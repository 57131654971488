/***
DFC Partner styles
***/

.primary-btn-dfc, .primary-btn-td, .primary-btn-fm, .primary-btn-tap, .primary-btn-rs, .primary-btn-sl, .primary-btn-acnl, .primary-btn-mngbw,
.primary-btn-skmm, .primary-btn-chive, .primary-btn-visualcapitalist, .primary-btn-flipside, .primary-btn-yourstate, .primary-btn-shermans{
  background-color: #3a86ff;
  border-color: #3a86ff;
  border-style: solid;
  border-width: 2px;
  color: white;
}

.primary-btn-dfc:hover, .primary-btn-td:hover, .primary-btn-fm:hover, .primary-btn-tap:hover, .primary-btn-rs:hover,
.primary-btn-sl:hover, .primary-btn-acnl:hover, .primary-btn-mngbw:hover, .primary-btn-skmm:hover, .primary-btn-chive:hover,
.primary-btn-visualcapitalist:hover, .primary-btn-flipside:hover, .primary-btn-yourstate:hover, .primary-btn-shermans:hover{
  background-color: #3a86ff;
  border-color: #3a86ff;
  box-shadow: 0 0 0 0.2rem rgba(38,143,255,.5);
  color: white;
}

.primary-btn-dfc:active, .primary-btn-td:active, .primary-btn-fm:active, .primary-btn-tap:active, .primary-btn-rs:active,
.primary-btn-sl:active, .primary-btn-acnl:active, .primary-btn-mngbw:active, .primary-btn-skmm:active, .primary-btn-chive:active,
.primary-btn-visualcapitalist:active, .primary-btn-flipside:active, .primary-btn-yourstate:active, .primary-btn-shermans:active{
  background-color: #3a86ff;
  border-color: #3a86ff;
}

.secondary-btn-dfc, .secondary-btn-td, .secondary-btn-fm, .secondary-btn-tap, .secondary-btn-rs,
.secondary-btn-sl, .secondary-btn-acnl, .secondary-btn-mngbw, .secondary-btn-skmm, .secondary-btn-chive,
.secondary-btn-visualcapitalist, .secondary-btn-flipside, .secondary-btn-yourstate, .secondary-btn-shermans{
  background-color: #fc4c02;
  border-color: #fc4c02;
  border-style: solid;
  border-width: 2px;
  color: white;
}

.secondary-btn-dfc:hover, .secondary-btn-td:hover, .secondary-btn-fm:hover, .secondary-btn-tap:hover, .secondary-btn-rs:hover,
.secondary-btn-sl:hover, .secondary-btn-acnl:hover, .secondary-btn-mngbw:hover, .secondary-btn-skmm:hover, .secondary-btn-chive:hover,
.secondary-btn-visualcapitalist:hover, .secondary-btn-flipside:hover, .secondary-btn-yourstate:hover, .secondary-btn-shermans:hover{
  box-shadow: 0 0 0 0.2rem rgba(38,143,255,.5);
  color: white;
}

.secondary-btn-dfc:active, .secondary-btn-td:active, .secondary-btn-fm:active, .secondary-btn-tap:active, .secondary-btn-rs:active,
.secondary-btn-sl:active, .secondary-btn-acnl:active, .secondary-btn-mngbw:active, .secondary-btn-skmm:active, .secondary-btn-chive:active,
.secondary-btn-visualcapitalist:active, .secondary-btn-flipside:active, .secondary-btn-yourstate:active, .secondary-btn-shermans:active{
  background-color: #fc4c02;
  border-color: #fc4c02;
}

.primary-btn-inverse-dfc, .primary-btn-inverse-td, .primary-btn-inverse-fm, .primary-btn-inverse-tap, .primary-btn-inverse-rs,
.primary-btn-inverse-sl, .primary-btn-inverse-acnl, .primary-btn-inverse-mngbw, .primary-btn-inverse-skmm, .primary-btn-inverse-chive,
.primary-btn-inverse-visualcapitalist, .primary-btn-inverse-flipside, .primary-btn-inverse-yourstate, .primary-btn-inverse-shermans{
  background-color: transparent;
  border-color: #3a86ff;
  border-style: solid;
  border-width: 2px;
  color: #3a86ff;
}

.primary-btn-inverse-dfc:hover, .primary-btn-inverse-td:hover, .primary-btn-inverse-fm:hover, .primary-btn-inverse-tap:hover,
.primary-btn-inverse-rs:hover, .primary-btn-inverse-sl:hover, .primary-btn-inverse-acnl:hover, .primary-btn-inverse-mngbw:hover,
.primary-btn-inverse-skmm:hover, .primary-btn-inverse-chive:hover, .primary-btn-inverse-visualcapitalist:hover, .primary-btn-inverse-flipside:hover,
.primary-btn-inverse-yourstate:hover, .primary-btn-inverse-shermans:hover{
  box-shadow: 0 0 0 0.2rem rgba(112, 125, 139, 0.5);
}

.primary-btn-inverse-dfc:focus, .primary-btn-inverse-td:focus, .primary-btn-inverse-fm:focus, .primary-btn-inverse-tap:focus,
.primary-btn-inverse-rs:focus, .primary-btn-inverse-sl:focus, .primary-btn-inverse-acnl:focus, .primary-btn-inverse-mngbw:focus,
.primary-btn-inverse-skmm:focus, .primary-btn-inverse-chive:focus, .primary-btn-inverse-visualcapitalist:focus, .primary-btn-inverse-flipside:focus,
.primary-btn-inverse-yourstate:focus, .primary-btn-inverse-shermans:focus{
  box-shadow: none;
}

.dfc-logo{
  height: 55px;
  margin-bottom: 60px;
}

.dfc-color, .dfc-td, .fm-color, .tap-color, .rs-color, .sl-color, .acnl-color, .mngbw-color, .skmm-color, .chive-color,
.visualcapitalist-color, .flipside-color, .yourstate-color, .shermans-color{
  color: #3a86ff!important;
}

.dfc-bg-color, .td-bg-color, .fm-bg-color, .tap-bg-color, .rs-bg-color, .sl-bg-color, .acnl-bg-color, .mngbw-bg-color,
.skmm-bg-color, .chive-bg-color, .visualcapitalist-bg-color, .flipside-bg-color, .yourstate-bg-color, .shermans-bg-color{
  background-color: #3a86ff;
}

.dfc-bg-primary-alt {
  background-color: #3a86ff8c;
}

.dfc-bg-secondary-alt{
  background-color: #fc4c026e;
}

.dfc-nav-logo, .td-nav-logo, .fm-nav-logo, .tap-nav-logo, .rs-nav-logo, .sl-nav-logo, .acnl-nav-logo, .mngbw-nav-logo, .skmm-nav-logo,
.chive-nav-logo, .visualcapitalist-nav-logo, .flipside-nav-logo, .yourstate-nav-logo, .shermans-nav-logo{
  margin-top: -10px!important;
}

/***
Skimm Partner Styles
***/

.skmm-logo{
  height: 100px;
  margin-bottom: 50px;
}

.mngbw-logo, .chive-logo, .yourstate-logo, .shermans-logo{
  height: 60px;
  margin-bottom: 60px;
}

.flipside-logo{
  height: 70px;
  margin-bottom: 60px;
}

/***
The Discoverer Partner Styles
***/

.td-logo{
  height: 90px;
  margin-bottom: 60px;
}

/***
Acanela Partner Styles
***/

.acnl-logo{
  height: 80px;
  margin-bottom: 60px;
}

/***
RealSimple Styles
***/

.rs-logo{
  height: 40px;
  margin-top: 15px;
  margin-bottom: 60px;
}

.rs-plan-logo{
  min-height: 105px;
  max-width: 170px;
}

/***
Frommers Partner Styles
***/

.fm-logo{
  height: 45px;
  margin-bottom: 60px;
}

.fm-plan-logo{
  min-height: 115px;
}

/***
Trip Advisor Partner Styles
***/

.tap-plan-logo{
  min-height: auto;
  max-width: 200px;
}

.tap-logo{
  height: 55px;
  margin-bottom: 60px;
  width: 40%;
  height: auto;
}

/***
Southern Living Partner Styles
***/

.sl-plan-logo{
  min-height: 105px;
  max-width: 170px;
}

.sl-logo{
  height: 55px;
  margin-bottom: 60px;
}


/***
Sourced Cheap Flights Partner styles
***/
.primary-btn-scf{
  background-color: #0070DF;
  border-color: #0070DF;
  border-style: solid;
  border-width: 2px;
  color: white;
}

.primary-btn-scf:hover{
  background-color: #0070DF;
  border-color: #0070DF;
  box-shadow: 0 0 0 0.2rem rgba(38,143,255,.5);
  color: white;
}

.primary-btn-scf:active{
  background-color: #0070DF;
  border-color: #0070DF;
}

.scf-logo{
  height: 115px;
  margin-bottom: 60px;
}

.scf-color{
  color: #0070DF!important;
}

.scf-bg-color{
  background-color: #0070DF;
}

.scf-nav-logo{
  margin-top: -15px!important;
}

/***
Travel Trivia Flight Deals Partner styles
***/
.primary-btn-ttfd{
  background-color: #00a6fe;
  border-color: #00a6fe;
  border-style: solid;
  border-width: 2px;
  color: white;
}

.primary-btn-ttfd:hover{
  background-color: #00d2ff;
  border-color: #00d2ff;
  box-shadow: 0 0 0 0.2rem rgba(38,143,255,.5);
  color: white;
}

.primary-btn-ttfd:active{
  background-color: #00d2ff;
  border-color: #00d2ff;
}

.ttfd-logo{
  height: 80px;
  margin-bottom: 60px;
}

.ttfd-color{
  color: #00a6fe!important;
}

.ttfd-bg-color{
  background-color: #00a6fe;
}

.ttfd-nav-logo{
  margin-top: -7px!important;
  max-width: 115px!important;
}

/***
Skyscanner Partner styles
***/
.primary-btn-ss{
  background-color: #3a86ff;
  border-color: #3a86ff;
  border-style: solid;
  border-width: 2px;
  color: white;
}

.primary-btn-ss:hover{
  background-color: #3a86ff;
  border-color: #3a86ff;
  box-shadow: 0 0 0 0.2rem rgba(38,143,255,.5);
  color: white;
}

.primary-btn-ss:active{
  background-color: #3a86ff;
  border-color: #3a86ff;
}

.primary-btn-inverse-ss{
  background-color: transparent;
  border-color: #fc4c02;
  border-style: solid;
  border-width: 2px;
  color: #fc4c02;
}

.primary-btn-inverse-ss:hover{
  box-shadow: 0 0 0 0.2rem rgba(112, 125, 139, 0.5);
}

.primary-btn-inverse-ss:focus{
  box-shadow: none;
}

.ss-logo{
  height: 55px;
  margin-bottom: 60px;
}

.ss-color{
  color: #3a86ff!important;
}

.ss-bg-color{
  background-color: #3a86ff;
}

/***
The Points Guy Deals Partner styles
***/
.primary-btn-tpg{
  background-color: #101567;
  border-color: #101567;
  border-style: solid;
  border-width: 2px;
  color: white;
}

.primary-btn-tpg:hover{
  background-color: #101567;
  border-color: #101567;
  box-shadow: 0 0 0 0.2rem rgba(38,143,255,.5);
  color: white;
}

.primary-btn-tpg:active{
  background-color: #101567;
  border-color: #101567;
}

.tpg-logo{
  height: 80px;
  margin-bottom: 60px;
}

.tpg-color{
  color: #101567!important;
}

.tpg-bg-color{
  background-color: #101567;
}

.tpg-nav-logo{
  margin-top: -7px!important;
  max-width: 115px!important;
}

@media(max-width:425px){
  .mngbw-logo, .yourstate-logo{
    height: 43px;
    margin-bottom: 30px;
  }

  .shermans-logo{
    height: 40px;
    margin-bottom: 30px;
  }

  .chive-logo, .flipside-logo{
    height: 50px;
    margin-bottom: 30px;
  }
}
