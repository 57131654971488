.content-area{
    margin-left: 13.5rem;
}

.content-wrapper{
    padding: .5rem 9rem;
}

@media(max-width:1440px){
    .content-wrapper{
        padding: 1rem 8.5rem;
    }
}

@media(max-width:1199px){
    .content-area{
        margin-left: 0rem;
    }
    .content-wrapper{
        padding: 1rem 2rem;
    }
}

@media(max-width:768px){
    .content-wrapper{
        padding: 1rem 1rem;
    }
}