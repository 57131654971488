

.gray-txt{
    color: #6C7A89;
}

body{
	font-size: .93rem;
}

h1{
	font-size: 2rem !important;
}

h2{
	font-size: 1.7rem !important;
}

h3{
	font-size: 1.5rem !important;
}

.font16
{
	font-size: 16px!important;
	font-weight: normal!important;
}

.font14{
  font-size: 14px!important;
}

.font13{
  font-size: 13px!important;
}

.font12 {
	font-size: 12px!important;
	font-weight: normal!important;
}

.font11 {
  font-size: 11px!important;
}

@media(max-width:768px)
{
	h1{
		font-size: 1.6rem !important;
	}

	h2{
		font-size: 1.4rem !important;
	}

	h3{
		font-size: 1.3rem !important;
	}

	h5{
		font-size: 1.1rem!important;
	}
}

@media(max-width: 320px){
	.font-xs-12	{
		font-size: 12px!important;
		font-weight: normal!important;
	}
}
