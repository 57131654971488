@import './assets/css/bootstrap-reboot.min.css';
@import './assets/css/bootstrap.min.css';
@import './assets/css/bootstrap-grid.min.css';
@import './assets/css/animate.css';
@import './assets/fonts/font-awesome/css/font-awesome.min.css';
@import './assets/fonts/googlefonts/fonts.css';
@import './assets/css/styles.css';
@import './assets/css/layout.scss';
@import './assets/css/typography.css';
@import './assets/css/utils.css';
@import './assets/css/responsive.css';
@import './assets/css/ngx-smart-modal.css';
@import './assets/css/partner_themes.scss';
@import './assets/css/icons.scss';
@import './assets/css/signup.css';
@import "@fortawesome/fontawesome-free/css/all.css";

html, body{
  min-height: 100%;
}
html, body {
  height: 100%;
  margin: 0;
}

.content-area {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
}


