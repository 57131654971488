body {
  width: 100%;
  overflow-x: hidden;
  font-family: 'Open Sans', sans-serif;
  background: #FFFFFF !important;
  margin: auto;
  display: inline-block;
}

h6{
  line-height: 26px;
}

.font-14{
  font-size: 14px;
}

.font-12{
  font-size: 12px;
}

.open-sans {
  font-family: 'Open Sans', sans-serif;
}

.clear {
  clear: both;
}

.carousel *{
  -webkit-tap-highlight-color: transparent;
}

.carousel-indicators li{
  background-clip: initial;
  width: 7px;
  height: 7px;
  border-radius: 50px;
  background-color: #3a86ff!important;
  border: 0px solid transparent;
}

.carousel-control-next, .carousel-control-prev{
  filter: invert(64%) sepia(72%) saturate(5374%) hue-rotate(200deg) brightness(98%) contrast(106%);
  width: 8%;
  height: 70%;
  top: 100px;
}

.carousel-indicators{
  bottom: -15%;
}

.carousel-indicators li{
  margin-right: 8px;
  margin-left: 8px;
}

.carousel-inner{
  overflow: initial;
}

.box-shadow{
  box-shadow: rgba(0,0,0,.3) 0 0 15px;
}

.clear-20 {
  clear: both;
  margin-bottom: 20px;
}

.top50 {
  margin-top: 50px !important;
}

.top20 {
  margin-top: 15px !important;
}

.btn-default {
  border-radius: 20px;
  border: solid 2px #999;
  color: #999;
}

.btn-default:hover {
  border-color: #666;
  color: #666;
  background: #FFF;
}

.btn-primary {
  border: solid 2px #999;
  color: #999;
  border-radius: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: center;
  background-color: #fc4c02;
  border-color: #fc4c02;
  color: #FFF;
}

.btn-primary:hover:not(.loading) {
  background-color: #fc4c02;
  border-color: #fc4c02;
}

.btn-primary:active:not(.loading) {
  background-color: #fc4c02;
  border-color: #fc4c02;
}

.btn-primary:focus:not(.loading) {
  background-color: #fc4c02;
  border-color: #fc4c02;
}

.primary-btn-inverse{
  background-color: white;
  border-style: solid;
  border-width: 2px;
  color: #3A86FF;
}

.primary-btn-inverse:hover{
  background-color: #ffffffcc;
}

.loader {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.98);
  background-image: url('../images/loader.gif');
  background-position: center;
  background-repeat: no-repeat;
  z-index: 99999999;
  font-size: 14px;
  text-align: center;
  color: #333;
}

.loader p {
  position: absolute;
  top: 57%;
  left: 0px;
  width: 100%;
  text-align: center;
}

.mobile-only {
  display: none;
}

.raleway {
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  font-size: 12px;
  padding-right: 10px;
}

button.loading {
  background-color: #ECECEC !important;
  color: #ECECEC !important;
  border-color: #ECECEC !important;
  background-image: url('../images/btnloader.gif') !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  color: transparent !important;
  box-shadow: none !important;
}

.input-error {
  border: solid 1px #F03434 !important;
  background-image: url('../images/input-error.png') !important;
  background-position: 98% 13px !important;
  background-repeat: no-repeat !important;
}

.input-success {
  border: solid 1px #21d249 !important;
  background-image: url('../images/success1.png') !important;
  background-repeat: no-repeat !important;
  background-position: 93% 10px !important;
  background-size: 15px;
}

.input-error-message {
  display: block;
  padding-top: 3px;
  color: #F03434;
  font-size: 11px;
  text-align: left;
}

.space-filler {
  flex: 1 1;
}

button,
button:active,
button:focus,
button:hover,
.btn,
.btn:active,
.btn:focus,
.btn:hover {
  outline: none !important;
}

.modal-lg {
  max-width: 1140px;
  /* width: 1140px; */
}

.modal-message-container {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 99999999;
  overflow-x: hidden;
  overflow-y: auto;
}

.modal-message-container .message {
  position: absolute;
  top: 20%;
  left: 30%;
  width: 40%;
  background: #FFF;
  border: solid 1px #CCC;
  padding: 20px;

}

.modal-message-container .message h1 {
  font-size: 24px;
  text-transform: uppercase;
  margin: 0px;
  padding: 0px;
  margin-bottom: 20px;
}

.modal-message-container .message p {
  font-size: 16px;
}

.modal-message-container .message a {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  color: #CCC;
  text-decoration: none;
}

.modal-message-container .message a.action {
  font-size: 12px;
}

.modal-message-container .message a:hover {
  color: #333;
}

.modal-message-container .message p {
  background-image: url('../images/stripe-logo.png');
  background-position: left;
  background-repeat: no-repeat;
  padding-left: 150px;
  min-height: 128px;
}

.square-button {
  border-radius: 0px !important;
  text-transform: uppercase !important;
}

.padding-bottom-20px {
  padding-bottom: 20px !important;
}

.btn-accent {
  background: #fc4c02;
  color: #FFF !important;
}

.btn-accent:active,
.btn-accent:hover,
.btn-accent:focus {
  background: #f67441;
}

.btn.square {
  border-radius: 0px;
}

.alert-light {
  box-shadow: 0 0 2px 0 rgba(146, 146, 146, 0.2), 0 2px 20px 0 rgba(146, 146, 146, 0.2);
  border-radius: 10px;
}

i.fa-alarm {
  background-image: url('../images/alarm.png') !important;
  background-repeat: no-repeat;
  width: 48px;
  height: 48px;
  display: inline-block;
  float: left;
  margin-right: 20px;
}

.alert h1 {
  font-size: 18px;
  color: #333;
  margin: 0px;
  padding: 0px;
  margin-bottom: 5px;
}

.alert h1.bold {
  font-weight: 800 !important;
  font-family: Arial, Helvetica, sans-serif !important;
  color: #000 !important;
  font-size: 16px !important;
  margin-bottom: 5px !important;
  padding: 0px !important;
}

.bold {
  font-weight: bold;
}

.alert.centered {
  text-align: center;
}

.alert.footer {

  display: block;

  width: 100% !important;
  border-radius: 0px !important;
  margin: 0px !important;
}

.alert.footer h1 {
  font-size: 16px !important;
  margin-bottom: 5px !important;
}

.alert.centered .alert-text {
  display: inline-block !important;
  max-width: 60% !important;
  font-size: 14px !important;
}

.alert div.pull-left {
  max-width: 80% !important;
}

.alert-light .btn {
  display: inline-block !important;
}

.alert-light .btn.btn-white {
  background: #FFF !important;
  color: #333 !important;
  border-radius: 10px !important;
  border: solid 1px #efedf6 !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  padding-left: 30px !important;
  padding-right: 30px !important;
  display: inline-block !important;
}

.icon_increase {
  background-image: url('../images/bars.png') !important;
  background-repeat: no-repeat;
  width: 64px;
  height: 64px;
  display: inline-block;
}

.icon_savings {
  background-image: url('../images/piggy-bank.png') !important;
  background-repeat: no-repeat;
  width: 64px;
  height: 64px;
  display: inline-block;
}

.icon_suitcase {
  background-image: url('../images/suitcase.png') !important;
  background-repeat: no-repeat;
  width: 64px;
  height: 64px;
  display: inline-block;
}

.card.tablecard.flat,
.card.tablecard.flat .row,
.card.tablecard.flat h1 {
  padding-left: 0px !important;
  padding-right: 0px !important;
  border: none;
}

.card.tablecard.flat h1 {
  background: transparent !important;
  color: #000;
  font-weight: 800 !important;
  font-size: 20px !important;
  font-family: Arial, Helvetica, sans-serif;
  text-transform: inherit;
  border-bottom: solid 1px #000;
  padding-left: 20px !important;
}

.card.tablecard.flat h1 a {
  color: #000 !important;
  font-weight: normal !important;
  padding-top: 10px;
}

.noscroll {
  overflow: hidden !important;
}

.rounded-btn{
  border-radius: 4px;
}

div.h-heading {
  text-align: center;
  font-size: 24px;
  color: #000;
}

div.h-heading.gray {
  color: #6C7A89;
}

.bordered {
  border: solid 2px #6C7A89;
  border-radius: 5px;
  padding-bottom: 40px !important;
  padding-top: 40px !important;
}

.bordered h3 {
  margin-top: 0px !important;
  padding-top: 0px !important;
  font-size: 18px;
}

.bordered h2 {
  margin-top: 0px !important;
  padding-top: 0px !important;
  font-size: 23px;
  font-weight: 700;
  text-transform: uppercase;
  color: #fc4c02;
}

.btncol {
  padding-top: 5%;
}

.btncol .btn {
  border-radius: 25px;
}

.bordered.best {
  border-color: #fc4c02;
  position: relative;
}

.stripe {
  display: inline-block;
  background-image: url('../images/stripe.png');
  background-repeat: no-repeat;
  background-position: right;
  min-height: 96px;
  margin-top: 10px;
}

.bestvalue {
  display: inline-block;
  background: #fc4c02;
  background-repeat: no-repeat;
  width: 21px;
  height: 120px;
  position: absolute;
  left: -21px;
  top: 10%;
  border-radius: 3px 0px 0px 3px;
  font-size: 10px;

}

.bestvalue div {
  transform: rotate(-90deg);
  transform-origin: left top 0;
  width: 120px;
  display: block;
  position: absolute;
  bottom: -20px;
  left: 0px;
  text-align: center;
  font-weight: 400;
  color: #FFF;
  padding-top: 3px;
}

.road {
  background-image: url('../images/road.jpg');
  background-repeat: no-repeat;
  display: block;
  height: 500px;
  background-size: cover;
  position: relative;
}

.road .cover {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.3);
  color: #FFF;
  text-align: center;
  font-size: 24px;
  padding-top: 15%;
  width: 100%;
  height: 100%;
}

.imageblock {
  width: 50%;
  height: 500px;
  display: inline-block;
  background-color: #FFF;
  position: relative;
  float: left;
}

.imageblock.ocean {
  background-image: url('../images/ocean.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}

.imageblock.wild {
  background-image: url('../images/wild.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}

.imageblock .cover {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
  color: #000;
  text-align: center;
  font-size: 24px;
  padding-top: 15%;
  width: 100%;
  height: 100%;
  padding-top: 15%;
  padding-left: 10%;
  padding-right: 10%;
}

.imageblock .cover .signature {
  display: block;
  text-align: center;
  color: #6C7A89;
  font-size: 14px;
  margin-top: 20px;
}

ul.side-menu {
  display: block;
  border: solid 1px #6C7A89;
  border-top: none;
  padding: 0px;
  margin: 0px;
  list-style: none;
  margin-bottom: 40px;
}

ul.side-menu li {
  display: block;
  text-align: left;
  padding: 20px;
  font-size: 16px;
  border-top: solid 1px #6C7A89;
  cursor: pointer;
}

ul.side-menu li.active {
  background: #fc4c02;
  color: #FFF;
}

ul.profile {
  list-style: none;
  margin: 0px;
  padding: 0px;
  margin-top: 20px;
}

ul.profile li {
  display: block;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #000;
  font-weight: bold;
}

ul.pointer li *:hover{
  cursor: pointer;
}

ul.profile li label.main-label {
  display: block;
  color: #6C7A89;
  padding-right: 20px;
  font-weight: normal;
  text-align: left;
  padding-left: 15px;
}

ul.profile li:not(.nohover):hover,
ul.profile li.active {
  background-color: #f6f5fa;
}

ul.profile li:not(.nohover):hover:not(.active) {
  background-position: 95% 20px;
  background-repeat: no-repeat;
}

ul.profile li input {
  max-width: 80%;
}

ul.profile li .btn {
  border-radius: 0px;
}

ul.profile li a.close {
  border: none;
  font-weight: normal;
  color: #545454 !important;
  display: inline-block;
  font-size: 33px;
  padding-left: 20px;
  padding-right: 20px;
}

ul.profile li .form-check label {
  padding-left: 5px;
}

.bold {
  font-weight: bold !important;
}

span.tab {
  /* border-top: 1px solid black;
	border-left: 1px solid black;
	border-right: 1px solid black; */
  padding: 2px 6px;
  /* border-radius:3px; */
}

span.tab:hover {
  cursor: pointer;
}

span.tab.active {
  color: #fc4c02;
  /* color: white; */
}


.wrap-box {
  width: 100%;
  margin: 0 auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.wrap-box.wrap-light {
  background: #fff;
}

.wrap-box .wrap-item {
  width: 25vw;
  min-width: 325px;
  margin: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  padding: 0px 10px;
}

.wrap-box .wrap-item .icon-background {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #E5E9F2;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrap-box .wrap-item .icon-background .icon {
  width: 75px;
  height: 75px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 75px 75px;
}

.logo-center {
  min-height: 130px;
  max-height: 175px;
  max-width: 145px;
  border: 1px solid transparent;
  padding: 9% 5%;
  cursor: pointer;
}
.logo-center:hover, .logo-center.selected {
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 10px;
}

a.third-tab-font{
  font-size: 20px!important;
}

.third-tab{
  padding: 3px 7px!important;
  border-radius: 5px;
}

.pwr-block{
  float: right;
  transform: translate(-9em, 8px);
}

.mt-border{
  border-top: solid 1px #dddddd;
}

.mr-border{
  border-right: solid 1px #dddddd;
}

.nmx-1{
  margin-left: -15px;
  margin-right: -15px;
}

.modal-close:hover{
  background-color: #f7f7f785;
  border-radius: 0 0 12px 12px;
}

.modal-close-left:hover{
  background-color: #f7f7f785;
  border-radius: 0 0 0px 12px;
}

.modal-close-right:hover{
  background-color: #f7f7f785;
  border-radius: 0 0 12px 0px;
}

.powered-by-txt{
  display: inline-block;
  font-size: 13px;
}

.powered-by-logo{
  display: inline-block;
  position: absolute;
  width: 145%;
	height: 65px;
	background-image: url('https://dfcmedia.s3.amazonaws.com/email-assets/DFC_signature.png');
	background-position: top left;
	background-repeat: no-repeat;
	background-size: 70px 70px;
	background-color: transparent;
  z-index: 3;
  margin-left: 5px;
  margin-top: -28px;
}

.view-all{
  transform: translateX(60px);
}

@media(max-width:414px){
  .logo-center {
    min-height: 100px;
    max-height: 135px;
    max-width: 120px;
  }
}
