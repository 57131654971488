@media(max-width:1024px)
{
    .mobile-hidden
    {
        display: none;
    }
    .mobile40
    {
        width: 40%!important;
    }
    .mobile60
    {
        width: 60%!important;
    }
    body
    {
        font-size: 14px;
    }
    p
    {
        font-size: 16px;
    }


    .mobile-only
    {
        display: block;
    }

    .modal-message-container .message
    {
        top: 30%;
        left: 20%;
        width: 60%;
    }
    .modal-message-container .message p
    {
        font-size: 16px;
        display: block;
    }
    .modal-message-container .message h1
    {
        padding-top: 20px;
    }

    .pwr-block{
        display: none;
    }

    .view-all{
        transform: translateX(0px);
    }

}
@media(max-width:768px)
{

    .modal-message-container .message
    {
        top: 30%;
        left: 10%;
        width: 80%;
    }

    a.third-tab-font{
        font-size: 14px!important;
    }
}
@media(max-width:414px)
{
    .travel-guides{
        display: none;
    }
    .raleway
    {
        font-size: 10px;
    }
    .btn-mobile
    {
        font-size: 9px!important;
    }
    .imageblock
    {
        width: 100%;
    }
    .road .cover
    {
        padding-top: 40%;
    }
    .alert.centered .alert-text
    {
        display: block!important;
        width: 100%!important;
        max-width: 100%!important;
        margin-bottom: 10px;
    }
    .mobile-hidden
    {
        display: none;
    }
    p
    {
        font-size: 15px;
    }

    button.btn
    {
        padding-top: 10px;
        padding-bottom: 10px;
        font-size: 16px;
    }
    .margin20
    {
        margin-bottom: 20px;
    }

    .modal-message-container .message
    {
        top: 20%;
        left: 5%;
        width: 90%;
    }
    .modal-message-container .message h1
    {
        font-size: 20px;
    }
    .modal-message-container .message.success p
    {
        background-image: url('../images/success.png');
        background-position: top center;
        background-repeat: no-repeat;
        padding-left: 0px;
        padding-top: 220px;
    }
    .modal-message-container .message.error p
    {
        background-image: url('../images/error.png');
        background-position: top center;
        background-repeat: no-repeat;
        padding-left: 0px;
        padding-top: 220px;
    }

    .modal-message-container .message.warning p
    {
        background-image: url('../images/warning.png');
        background-position: top center;
        background-repeat: no-repeat;
        padding-left: 0px;
        padding-top: 120px;
    }

    .third-tab{
        display: none;
    }
}
@media(max-width:375px)
{

	.mini-hidden
	{
		display:none;
    }
    .dealimg .cover
    {
        left: 13px;
        width: 250px;
    }
    .modal-message-container .message
    {
        top: 5%;
        left: 5%;
        width: 90%;
    }
}


