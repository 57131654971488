body
{
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
}
h1,h2,h3,h4,h5
{
  font-family: 'Raleway', sans-serif;
  /* font-weight: 600; */
}

h1,h2{
  font-weight: 600;
}

h6{
  line-height: 26px;
}

p{
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
}

.grey-bg{
  background-color: #f9f9f9;
  border: 1px solid #f9f9f9;
}

.loader
{
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color:rgba(255,255,255,0.98);
  background-image:url('../images/loader.gif');
  background-position: center;
  background-repeat: no-repeat;
  z-index: 99999999;
  font-size: 14px;
  text-align: center;
  color: #333;
}
.loader p
{
  position: absolute;
  top: 57%;
  left: 0px;
  width: 100%;
  text-align: center;
}

.modal-close:hover{
  background-color: #f7f7f785;
  border-radius: 0 0 12px 12px;
}

.mt-border {
  border-top: solid 1px #dddddd;
}

.logo
{
  display: block;
  position: relative;
  /* top: 0px;
  left: 5%; */
  width: 100%;
  height: 120px;
}

.pwr-block{
  display: inline-flex;
  width: 100%;
  justify-content: center;
}

button:focus{
  outline: 0px auto -webkit-focus-ring-color!important;
}

.powered-by-logo{
  display: inline-block;
  width: 70px;
  height: 65px;
  background-image: url('https://dfcmedia.s3.amazonaws.com/email-assets/DFC_signature.png');
  background-position: top left;
  background-repeat: no-repeat;
  background-size: 70px 70px;
  background-color: transparent;
  z-index: 3;
  margin-left: 5px;
}

.powered-by-txt{
  display: inline-block;
  margin-top: 30px;
}

.logo-center {
  max-height: 175px;
  max-width: 145px;
  border: 1px solid transparent;
  padding: 9% 5%;
  cursor: pointer;
}

.logo-center:hover, .logo-center.selected {
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 10px;
}

.deal-card{
  border-radius: 8px;
  box-shadow: rgba(0,0,0,.2) 0 0 8px;
  letter-spacing: 2px;
}

.img-top-round{
  border-radius: 8px 8px 0px 0px;
}

.alt-color{
  color: #f46455;
}

.container-100 {
  width: 100%;
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  /* padding: 15px; */
  background: #f2f2f2;
  padding-top: 50px;
}

.container-contact100 {
  width: 100%;
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  /* padding: 15px; */
}

.wrap-contact100 {
  /* width: 1120px; */
  width: 100%;
  background: #fff;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  flex-direction: row;

}

/*==================================================================
[ Contact more ]*/
.contact100-more {
  width: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 1;
  padding: 30px 15px 0px 15px;
  min-height: 100vh;
}

.contact100-more::before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0,0,0,0.1);
}

/*==================================================================
[ Form ]*/

.contact100-form {
  width: 50%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  /* flex-wrap: wrap; */
  flex-direction: column;
  justify-content: flex-start;
  padding: 40px 55px 63px 55px;
}


/* Sourced Adventure CSS */

.btn-sa-primary{
  background-color: #0070DF;
  border-color: #0070DF;
  border-color: #0070DF;
  border-style: solid;
  border-width: 2px;
  color: white;
}

/* BOOTSTRAP OVERRIDES */
.btn-primary{
  background-color: #fc4c02;
  border-color: #fc4c02;
  border-style: solid;
  border-width: 2px;
}

.btn-primary-inverse{
  background-color: transparent;
  border-color: #fc4c02;
  border-style: solid;
  border-width: 2px;
  color: #fc4c02;
}

.btn-primary-inverse:hover{
  box-shadow: 0 0 0 0.2rem rgba(38,143,255,.5);
}
.btn-primary:hover{
  background-color: #3a86ff;
  border-color: #3a86ff;
  box-shadow: 0 0 0 0.2rem rgba(38,143,255,.5);
}

.btn-primary:active{
  background-color: #3a86ff !important;
  border-color: #3a86ff !important;
}

.btn-lg{
  padding: 1rem 1rem;
  border-radius: .9rem;
}

.next-btn{
  position: absolute;
  bottom: 5%;
  right: 55%;
  color: #fc4c02 !important;
}

.back-btn{
  position: absolute;
  bottom: 5%;
  left: 5%;
  color: #fc4c02 !important;
}

.capture-form{
  margin: 60px auto;
  width: 70%;
  max-width: 400px;
}

.input-error
{
  border:solid 1px #F03434!important;
  background-image: url('../images/input-error.png')!important;
  background-position:98% 13px!important;
  background-repeat: no-repeat!important;
  box-shadow: 0 0 0 0.2rem rgba(240,52,52,.25);
}

.input-error-message
{
  display: block;
  margin: 10px;
  color: #F03434;
  text-align: left;
}



.modal-lg{
  max-width: 700px;
  color: #333333;
  margin-left: auto;
  /* width: 1140px; */
}

/*.modal-content{*/
/*  padding-bottom: 15px;*/
/*}*/


.modal-message-container
{
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color:rgba(0,0,0,0.8);
  z-index: 99999999;
  overflow-x:hidden;
  overflow-y:auto;
}
.modal-message-container .message
{
  position: absolute;
  top: 20%;
  left: 30%;
  width: 40%;
  background:#FFF;
  border:solid 1px #CCC;
  padding: 20px;
  border-radius: 15px;

}
.modal-message-container .message h1
{
  font-size: 24px;
  margin: 0px;
  padding: 0px;
  margin-bottom: 20px;
}
.modal-message-container .message p
{
  font-size: 16px;
}
.exit
{
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  color: #CCC;
  text-decoration: none;
}
.modal-message-container .message a.action
{
  font-size: 12px;
}
.modal-message-container .message a:hover
{
  color:#333;
}
.modal-message-container .message p
{
  background-image: url('../images/logo.svg');
  background-position: left;
  background-repeat: no-repeat;
  padding-left: 140px;
  min-height: 70px;
  background-size: 123px 70px;
}

.basic-logo-center{
  padding: 35px 0 50px 38px;
  margin-left: 50px;
}

.checkout-logo{
  padding: 30px 0;
}

.responsive-block{
  margin-top: 35em;
  left: 20.5%;
}

.mt-6{
  margin-top: 120px;
}

.full-width{
  width: 85px;
}

@media(max-width:1440px)
{
  .responsive-block{
    margin-top: 39.5em;
    left: 19%;
  }
}

@media(max-width:1366px)
{
  .basic-modal-header{
    font-size: 2.2rem;
  }

}

@media(max-width:1200px)
{
  .responsive-block{
    margin-top: 43em;
    left: 18.5%;
  }

  .modal-lg{
    max-width: 600px;
  }
}

@media(max-width:1024px)
{
  .responsive-block{
    left: 17%!important;
    margin-top: 44em;
  }
  .modal-message-container .message
  {
    top: 30%;
    left: 20%;
    width: 60%;
  }
  .modal-message-container .message p
  {
    font-size: 16px!important;
    display: block;
  }
  .modal-message-container .message h1
  {
    padding-top: 20px;
  }
}

@media (max-width: 992px) {
  .contact100-form {
    width: 60%;
    padding: 56px 30px 63px 30px;
  }

  .contact100-more {
    width: 40%;
  }

  .responsive-block{
    left: 22%!important;
    margin-top: 40em;
  }

}

@media(max-width:768px)
{
  .modal-content{
    width: 100%;
  }

  .responsive-block{
    left: 39%!important;
    margin-top: 34.5em;
  }

  .modal-message-container .message
  {
    top: 30%;
    left: 10%;
    width: 80%;
  }

  .contact100-form {
    width: 100%;
    padding-bottom: 110px;
  }

  .contact100-more {
    width: 100%;
  }

  .capture-form{
    margin: 35px auto;
    width: 90%;
  }
  .form-control-lg{
    height: calc(1.2em + 1.5rem + 2px);
  }
  .btn-lg{
    padding: .7rem;
  }

  .primary-btn-scf{
    border-width: 10px;
  }

  .modal-lg{
    max-width: 100%;
  }
}

@media(max-width:660px){
  .responsive-block{
    margin-top: 39em;
    left: 38%!important;
  }
}

@media (max-width: 576px) {
  .contact100-form {
    padding: 40px 15px 100px 15px;
  }

  .responsive-block{
    margin-top: 40em;
    left: 36%!important;
  }

  .btnbar{
    margin-top: 45px!important;
  }

  .container-contact100{
    min-height: auto;
  }
}

@media (max-width: 525px) {
  .responsive-block{
    margin-top: 43em;
    left: 35%!important;
  }

  .log-center{
    max-height: 110px;
  }

  .modal-content{
    border-radius: 0;
    border: 2px solid white;
  }
}

@media(max-width:425px){

  .modal-message-container .message p{
    background-position: 50% -40px;
    padding-left: 0px;
    background-size: 95px 129px;
    padding-top: 55px;
  }
}

@media(max-width:414px)
{
  .modal-message-container .message
  {
    top: 20%;
    left: 5%;
    width: 90%;
  }
  .modal-message-container .message h1
  {
    font-size: 20px;
  }
  .modal-message-container .message.success p
  {
    background-image: url('../images/success.png');
    background-position: top center;
    background-repeat: no-repeat;
    padding-left: 0px;
    padding-top: 60px;
  }
  .modal-message-container .message.error p
  {
    background-image: url('../images/success.png');
    background-position: top center;
    background-repeat: no-repeat;
    padding-left: 0px;
    padding-top: 60px;
  }
  .modal-message-container .message.travel p
  {
    background-image: url('../images/success.png');
    background-position: top center;
    background-repeat: no-repeat;
    padding-left: 0px;
    padding-top: 60px;
  }
  .modal-message-container .message.warning p
  {
    background-image: url('../images/success.png');
    background-position: top center;
    background-repeat: no-repeat;
    padding-left: 0px;
    padding-top: 60px;
  }

  .responsive-block{
    margin-top: 46em;
    left: 30%!important;
  }
}

@media (max-width: 375px) {

  .modal-message-container .message p{
    background-position: 50% -40px;
    padding-left: 0px;
    background-size: 85px 45px;
    padding-top: 55px;
  }

  .responsive-block{
    margin-top: 47.5em;
    left: 29%!important;
  }
}

@media (max-width: 320px) {

  .responsive-block{
    margin-top: 51em;
    left: 25%!important;
  }
}
