body.dialog-open {
  overflow: hidden; }

.nsm-dialog-btn-close {
  border: 0;
  background: none;
  color: #2d2d2d;
  position: absolute;
  font-size: 1.2em;
  left: 5px;
  top: 5px;
  cursor: pointer; }

.nsm-dialog-btn-close>img {
  content: url(https://dfcmedia.s3.amazonaws.com/email-assets/arrow-left.svg);
  height: 16.5px;
}

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-x: hidden;
  overflow-y: auto;
  transition: background-color 500ms;
  background-color: transparent;
  z-index: 999; }
  .overlay.nsm-overlay-open {
    background-color: rgba(0, 0, 0, 0.5); }
  .overlay.transparent {
    background-color: transparent; }

.md-dialog{
  max-width: 700px;
}

.lg-dialog{
  max-width: 1200px;
}

.dashboard-modal .nsm-content{
  padding: 1.5rem 0 0 0;
  margin-top: 25vh;
  width: 60%;
  margin-left: 9rem;
}

.dashboard-modal .nsm-content .nsm-dialog-btn-close{
  display: none;
}

.video-modal{
  margin: 0;
  text-align: center;
  max-width: 900px;
  top: 13%;
}

.video-modal > .nsm-content{
  padding:0;
  border-radius: 6px;
  background-color: transparent;
  box-shadow: 0 7px 8px -4px rgb(0 0 0 / 0%), 0 13px 19px 2px rgb(0 0 0 / 0%), 0 5px 24px 4px rgb(0 0 0 / 0%);
  width: 100%;
  margin-left: 0;
  margin-top: auto;
  margin-bottom: auto;
}

.update-modal > .nsm-content{
  padding: 0;
  border-radius: 6px;
  margin-top: 35%;
}

.update-modal .nsm-dialog-btn-close>img{
  content: url(https://dfcmedia.s3.amazonaws.com/email-assets/close-btn.svg);
  height: 16.5px;
}

.video-modal .nsm-dialog-btn-close>img {
  position: absolute;
  content: url(https://dfcmedia.s3.amazonaws.com/email-assets/close-btn.svg);
  height: 20px;
  filter: brightness(0) invert(1);
}

.video-modal .nsm-dialog-btn-close{
  right: 17px;
  left: inherit;
  top: 0;
}

.nsm-dialog {
  position: relative;
  opacity: 1;
  visibility: visible;
  min-height: 200px;
  width: 100%;
  margin: 0 auto;
  pointer-events: none; }
  .nsm-dialog.nsm-dialog-close {
    opacity: 0; }
  .nsm-dialog.nsm-centered {
    display: flex;
    align-items: center;
    min-height: calc(100% - (1.75rem * 2)); }

.nsm-content {
    position: relative;
    display: flex;
    flex-direction: column;
    pointer-events: auto;
    background-clip: padding-box;
    background-color: #fff;
    border-radius: 12px;
    padding: 1rem;
    margin-top: 1rem;
    margin-left: 6.5rem;
    box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2), 0 13px 19px 2px rgba(0, 0, 0, 0.14), 0 5px 24px 4px rgba(0, 0, 0, 0.12);
    outline: 0;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.nsm-body {
  position: relative;
  flex: 1 1 auto;
  color: hsla(0,0%,10%,.7);
}

/* *************************
* Animations
* *************************/
.nsm-dialog[class*=nsm-dialog-animation-] {
  transition: transform 500ms ease-in-out, opacity 500ms; }

.nsm-dialog-animation-ltr {
  transform: translate3d(-50%, 0, 0); }
  .nsm-dialog-animation-ltr.nsm-dialog-open {
    transform: translate3d(0, 0, 0); }
  .nsm-dialog-animation-ltr.nsm-dialog-close {
    transform: translate3d(-50%, 0, 0); }

.nsm-dialog-animation-rtl {
  transform: translate3d(50%, 0, 0); }
  .nsm-dialog-animation-rtl.nsm-dialog-open {
    transform: translate3d(0, 0, 0); }
  .nsm-dialog-animation-rtl.nsm-dialog-close {
    transform: translate3d(50%, 0, 0); }

.nsm-dialog-animation-ttb {
  transform: translate3d(0, -50%, 0); }
  .nsm-dialog-animation-ttb.nsm-dialog-open {
    transform: translate3d(0, 0, 0); }
  .nsm-dialog-animation-ttb.nsm-dialog-close {
    transform: translate3d(0, -50%, 0); }

.nsm-dialog-animation-btt {
  transform: translate3d(0, 50%, 0); }
  .nsm-dialog-animation-btt.nsm-dialog-open {
    transform: translate3d(0, 0, 0); }
  .nsm-dialog-animation-btt.nsm-dialog-close {
    transform: translate3d(0, 50%, 0); }

  @media(max-width:576px){
    .nsm-content{
      width: 100%;
      margin-left: 0;
      margin-top: 0;
      height: 121%;
      border-radius: 0;
    }

    .update-modal > .nsm-content, .video-modal > .nsm-content{
      height: auto;
    }

    .nsm-dialog{
      height: 100%;
    }

    .video-modal{
      top: 30%;
    }

    .dashboard-modal{
      width: 89%;
      margin-left: 20px;
    }

    .dashboard-modal .nsm-content{
      border-radius: 12px!important;
      margin-left: 0;
      width: auto;
      height: auto;
    }
  }

  @media(max-width:375px){
    .nsm-dialog{
      height: 100%;
    }

    .dashboard-modal{
      height: 39%;
    }
  }
